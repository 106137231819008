import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  getIsCategoryLabelLayoutText,
  getIsCategoryLabelUppercase,
  shouldUseMobileSettings,
} from '../../../post-list-widget/selectors/category-label-selectors';
import {
  getMainCategory,
  getMainCategoryDisplayLabel,
  getMainCategoryLink,
} from '../../store/categories/categories-selectors';
import { getTopology } from '../../store/topology/topology-selectors';
import CategoryLink from '../link/category-link';
import { connect } from '../runtime-context';
import styles from './post-list-item-category-label.scss';

const PostListItemCategoryLabel = ({
  mainCategory,
  mainCategoryDisplayLabel,
  mainCategoryLink,
  isCategoryLabelLayoutText,
  isCategoryLabelUpperCase,
  postListLayout,
  useMobileSettings,
  categoryLabelClicked,
  className,
}) => {
  const categoryLabelLayoutClass = classNames([
    styles.link,
    useMobileSettings && styles.mobile,
    isCategoryLabelLayoutText
      ? styles.categoryLabelText
      : styles.categoryLabelButton,
  ]);
  const containerClass = classNames([
    styles.container,
    styles[postListLayout],
    className,
  ]);
  const label = isCategoryLabelUpperCase
    ? mainCategoryDisplayLabel.toUpperCase()
    : mainCategoryDisplayLabel;

  const sendMainCategoryClickedBIEvent = () =>
    categoryLabelClicked(mainCategory);

  return (
    <div className={containerClass} data-hook="post-category-label">
      <CategoryLink
        categoryLink={mainCategoryLink}
        categoryPath={mainCategory.slug}
        className={categoryLabelLayoutClass}
        onClick={sendMainCategoryClickedBIEvent}
      >
        {label}
      </CategoryLink>
    </div>
  );
};

PostListItemCategoryLabel.propTypes = {
  post: PropTypes.object.isRequired,
  mainCategory: PropTypes.object.isRequired,
  mainCategoryDisplayLabel: PropTypes.string.isRequired,
  mainCategoryLink: PropTypes.string.isRequired,
  isCategoryLabelLayoutText: PropTypes.bool.isRequired,
  isCategoryLabelUpperCase: PropTypes.bool.isRequired,
  postListLayout: PropTypes.string.isRequired,
  categoryLabelClicked: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { post }, actions) => {
  const mainCategory = getMainCategory(state, post);

  return {
    mainCategory,
    mainCategoryDisplayLabel: getMainCategoryDisplayLabel(state, post),
    mainCategoryLink: getMainCategoryLink(state, post, getTopology(state)),
    isCategoryLabelLayoutText: getIsCategoryLabelLayoutText(state),
    isCategoryLabelUpperCase: getIsCategoryLabelUppercase(state),
    useMobileSettings: shouldUseMobileSettings(state),
    categoryLabelClicked: actions.categoryLabelClicked,
  };
};

export default connect(mapStateToProps)(PostListItemCategoryLabel);
