import { getPostCover, resolveId } from '@wix/communities-blog-client-common';
import { WIX_MEDIA_PREFIX } from './resizeMediaUrl';

const WIX_MEDIA_IMAGE_REGEX =
  /(?:https:\/\/static.wixstatic.com\/media\/)([\w\d.]+)(?:\/v\d\/(?:fit|fill)\/)w_(\d+)*(?:,|%2C)h_(\d+)/;
export const extractMetadataFromString = (url) => {
  const matches = url.match(WIX_MEDIA_IMAGE_REGEX);
  if (matches) {
    const [, filename, width, height] = matches;
    return {
      file_name: filename,
      width: +width,
      height: +height,
    };
  }

  return {
    file_name: '',
    height: 1,
    width: 10000,
  };
};

export const mapPostToPGItem = (post, hideCoverImage, emptyCoverImage) => {
  const itemId = resolveId(post);
  const emptyItem = {
    itemId,
    metaData: { ...emptyCoverImage, alt: post.title },
    url: '',
  };

  let { shouldRender, videoMetadata, imageMetadata } = getPostCover(post);

  if (hideCoverImage || !shouldRender) {
    return emptyItem;
  }

  if (imageMetadata) {
    if (
      typeof imageMetadata === 'string' &&
      imageMetadata.startsWith(WIX_MEDIA_PREFIX)
    ) {
      imageMetadata = extractMetadataFromString(imageMetadata);
    }

    return {
      itemId,
      metaData: {
        type: 'image',
        height: imageMetadata.height,
        width: imageMetadata.width,
        alt: post.title,
      },
      url: imageMetadata.id ?? imageMetadata.file_name,
    };
  } else if (videoMetadata) {
    const { height, width, thumbnail_height, thumbnail_width, thumbnail_url } =
      videoMetadata;
    return {
      itemId,
      metaData: {
        type: 'video',
        height: height || thumbnail_height,
        width: width || thumbnail_width,
        poster: {
          url: thumbnail_url,
          height: thumbnail_height,
          width: thumbnail_width,
        },
        alt: post.title,
      },
      url: videoMetadata.video_url,
    };
  }

  return emptyItem;
};
