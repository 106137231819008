import { useEffect, useState } from 'react';
import styles from './accessibility-listener.scss';

const ACTIVE_CLASS_NAME = 'blog-al-active';

export const AccessibilityListener = () => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (document.body.classList.contains(ACTIVE_CLASS_NAME)) {
      return;
    }

    setActive(true);

    document.body.classList.add(ACTIVE_CLASS_NAME);
    document.body.classList.add(styles.noOutline);

    document.addEventListener('keyup', handleTabKeyUp);
    document.addEventListener('click', handleClick);

    return () => {
      if (active) {
        document.body.classList.remove(ACTIVE_CLASS_NAME);
      }

      if (active) {
        document.removeEventListener('keyup', handleTabKeyUp);
        document.removeEventListener('click', handleClick);
      }
    };
  }, []);

  const handleTabKeyUp = (e: KeyboardEvent) => {
    if (e.which === 9 && document.body.classList.contains(styles.noOutline)) {
      document.body.classList.remove(styles.noOutline);
    }
  };

  const handleClick = () => {
    if (!document.body.classList.contains(styles.noOutline)) {
      document.body.classList.add(styles.noOutline);
    }
  };

  return null;
};
