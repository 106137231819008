import React, { Component } from 'react';
import classNames from 'classnames';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import withFontClassName from '../../hoc/with-font-class-name';
import withLayoutColorClasses from '../../hoc/with-layout-color-classes';
import withTranslate from '../../hoc/with-translate';
import { isSite } from '../../store/basic-params/basic-params-selectors';
import FocusTrap from '../focus-trap';
import MoreIcon from '../icons/more-icon';
import PopoverRoot from '../popovers/popover-root';
import { connect } from '../runtime-context';
import styles from './more-button.scss';

const BOTTOM_THRESHOLD = 60;

export class MoreButton extends Component {
  component = null;
  state = {
    isVisible: false,
  };

  handleClick = (e) => {
    e.stopPropagation();
    if (this.state.isVisible) {
      this.hideComponent();
      return;
    }

    this.showComponent();
  };

  setContainer = (element) => (this.container = element);

  setActionsContainer = (element) => (this.actionsContainer = element);

  checkIfShouldRenderRight = (outterContainerRec, buttonRec) => {
    const actionsWidth = this.actionsContainer
      ? this.actionsContainer.offsetWidth
      : 0;

    const availableSpaceAtRight = outterContainerRec.right - buttonRec.right;
    const availableSpaceAtLeft = buttonRec.left - outterContainerRec.left;

    return (
      availableSpaceAtRight > actionsWidth &&
      availableSpaceAtLeft < actionsWidth
    );
  };

  checkIfFitsInEditor = () => {
    const rect = this.container.getBoundingClientRect();
    const popoverContainer = this.getPopoverContainerRect();

    const scrollOffset =
      window.scrollY ||
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      0;
    const bodyHeight = document.body.offsetHeight;
    const topOffset = scrollOffset + rect.top;
    const bottomOffset = bodyHeight - topOffset - rect.height;
    const actionsHeight = this.actionsContainer
      ? this.actionsContainer.offsetHeight
      : 0;
    this.setState({
      isAtTop:
        actionsHeight > bottomOffset - BOTTOM_THRESHOLD &&
        actionsHeight < topOffset,
      isAtRight: this.checkIfShouldRenderRight(popoverContainer, rect),
    });
  };

  checkIfFits = () => {
    const moreButtonRect = this.container.getBoundingClientRect();
    const popoverContainer = this.getPopoverContainerRect();
    const rootRect = document
      .getElementById(this.props.componentId)
      .getBoundingClientRect();
    const actionsHeight = this.actionsContainer
      ? this.actionsContainer.offsetHeight
      : 0;
    const availableSpaceAtTop = moreButtonRect.top - Math.max(rootRect.top, 0);
    const availableSpaceAtBottom =
      window.innerHeight > rootRect.bottom
        ? rootRect.bottom - Math.max(moreButtonRect.bottom, 0)
        : window.innerHeight - Math.max(moreButtonRect.bottom, 0);

    this.setState({
      isAtTop:
        availableSpaceAtTop > actionsHeight &&
        availableSpaceAtBottom < actionsHeight,
      isAtRight: this.checkIfShouldRenderRight(
        popoverContainer,
        moreButtonRect,
      ),
    });
  };

  showComponent = async () => {
    this.component = await this.props.children();

    document.addEventListener('click', this.hideComponent);
    this.setState(
      {
        isVisible: true,
      },
      this.props.isSite ? this.checkIfFits : this.checkIfFitsInEditor,
    );
  };

  hideComponent = () => {
    document.removeEventListener('click', this.hideComponent);
    this.setState({
      isVisible: false,
    });
  };

  componentWillUnmount = () => {
    document.removeEventListener('click', this.hideComponent);
  };

  getPopoverContainerRect = () => {
    return (
      this.props.container || PopoverRoot.getPopoverRootElement()
    ).getBoundingClientRect();
  };

  calculateActionsContainerPosition = () => {
    const { isAtTop, isAtRight } = this.state;
    const containerRect = this.getPopoverContainerRect();
    const buttonRect = this.container.getBoundingClientRect();

    const verticalPosition = isAtTop
      ? {
          top: 'auto',
          bottom: containerRect.bottom - buttonRect.bottom,
        }
      : {
          top: buttonRect.top - containerRect.top + this.container.clientHeight,
        };
    const horizontalPosition = isAtRight
      ? {
          left: buttonRect.right - containerRect.left,
        }
      : { right: containerRect.right - buttonRect.left };
    return {
      ...verticalPosition,
      ...horizontalPosition,
    };
  };

  renderActionsContainer = () => {
    if (!this.state.isVisible) {
      return null;
    }
    const actionsContainerClass = classNames(
      styles.actions,
      this.props.contentFontClassName,
      'blog-text-color',
      'blog-dropdown-background-color',
    );

    const position = this.calculateActionsContainerPosition();

    const actionsContainer = (
      <div
        ref={this.setActionsContainer}
        className={actionsContainerClass}
        style={position}
        role="menu"
        data-hook="actions"
      >
        <FocusTrap active={this.state.isVisible}>{this.component}</FocusTrap>
      </div>
    );
    return ReactDOM.createPortal(
      actionsContainer,
      this.props.container || PopoverRoot.getPopoverRootElement(),
    );
  };

  render = () => {
    const { id, icon, t, isWhite, className: buttonClassName } = this.props;
    const className = classNames(buttonClassName, styles.more, 'more-button', {
      [styles.moreWhite]: isWhite,
    });

    return (
      <>
        <button
          onClick={this.handleClick}
          className={className}
          ref={this.setContainer}
          onKeyUp={(e) => {
            if (e.key === 'Escape') {
              this.hideComponent();
            }
          }}
          type="button"
          aria-pressed={this.state.isVisible}
          aria-label={t('more-button.more-actions')}
          id={id}
          data-hook="more-button"
        >
          <span className={styles.icon}>
            {icon || (
              <MoreIcon
                className={classNames(
                  this.props.iconColorClassName,
                  'blog-post-homepage-link-hashtag-hover-fill',
                )}
              />
            )}
          </span>
        </button>
        {this.renderActionsContainer()}
      </>
    );
  };
}

MoreButton.propTypes = {
  contentFontClassName: PropTypes.string.isRequired,
  children: PropTypes.func,
  className: PropTypes.string,
  isWhite: PropTypes.bool,
  id: PropTypes.string,
  icon: PropTypes.node,
  t: PropTypes.func.isRequired,
  iconColorClassName: PropTypes.string.isRequired,
  componentId: PropTypes.string,
  container: PropTypes.object,
  isSite: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  isSite: isSite(state),
  componentId: host.id,
});

export default flowRight(
  withTranslate,
  withFontClassName,
  withLayoutColorClasses,
  connect(mapRuntimeToProps),
)(MoreButton);
