import React from 'react';
import classNames from 'classnames';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import {
  SECTION_HOMEPAGE,
  SECTIONS,
} from '@wix/communities-blog-client-common';
import List from '../../../common/components/list';
import { connect } from '../../../common/components/runtime-context';

import { BulletSeparatorForPost } from '../../../common/components/separator';
import withIsFeedDesignEnabled from '../../../common/hoc/with-is-feed-design-enabled';

import styles from './post-metadata-layout.scss';

const PostMetadataLayout = ({
  className,
  applyFeedDesign,
  getPostClassName,
  ...itemProps
}) => {
  const separatorProps = {
    className: styles.separator,
    ...(applyFeedDesign
      ? {
          separatorBackgroundColorClassName: classNames(
            getPostClassName('description-background-color'),
          ),
        }
      : {}),
  };

  return (
    <List
      itemClassName={styles.item}
      containerClassName={classNames(styles.container, className)}
      separator={<BulletSeparatorForPost {...separatorProps} />}
      {...itemProps}
    />
  );
};

PostMetadataLayout.propTypes = {
  className: PropTypes.string,
  applyFeedDesign: PropTypes.bool,
  section: PropTypes.oneOf(SECTIONS),
  getPostClassName: PropTypes.func,
};

PostMetadataLayout.defaultProps = {
  section: SECTION_HOMEPAGE,
};

const mapRuntimeToProps = (_state, { section }) => ({ section });

export default flowRight(
  connect(mapRuntimeToProps),
  withIsFeedDesignEnabled,
)(PostMetadataLayout);
