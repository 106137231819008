import {
  isLayoutPGOneColumn,
  isLayoutPGSideBySide,
  isLayoutSlider,
  isLayoutTextOnImage,
  SECTION_MY_POSTS,
  SECTION_POST_LIST,
  SECTION_RELATED_POSTS,
} from '@wix/communities-blog-client-common';
import { getPostListCssVars } from './post-list-css-vars';
import Wix from './wix-sdk-polyfill';

export const getWrappersHorizontalMargin = ({
  layoutPGSideBySide,
  layoutPGOneColumn,
  layoutMargins,
  createdWithResponsiveEditor,
}) => {
  if (layoutPGSideBySide || layoutPGOneColumn) {
    return 'auto';
  }

  return createdWithResponsiveEditor ? 0 : layoutMargins;
};

const DEFAULT_MOBILE_WIDTH = 320;
const DEFAULT_DESKTOP_WIDTH = 980;

export const getContainerWidth = ({
  borderWidth,
  createdWithResponsiveEditor,
  hostWidth,
  isMobile,
  layoutMargins,
  layoutPostSize,
  layoutType,
  rootWidth,
  section,
  useLayoutFixerExperimentEnabled,
}) => {
  if (
    isLayoutPGSideBySide(layoutType) ||
    isLayoutPGOneColumn(layoutType) ||
    section === SECTION_MY_POSTS
  ) {
    return layoutPostSize;
  }

  const width = hostWidth || rootWidth;

  if (isNaN(width) || width === '') {
    if (useLayoutFixerExperimentEnabled) {
      return isMobile ? DEFAULT_MOBILE_WIDTH : DEFAULT_DESKTOP_WIDTH;
    }
    return 1;
  }

  if (isLayoutSlider(layoutType)) {
    return Math.max(1, width - layoutMargins * 2 - borderWidth * 2);
  } else if (createdWithResponsiveEditor || layoutMargins < 1) {
    return width;
  } else {
    return Math.max(1, width - layoutMargins * 2);
  }
};

export const getContainer = ({
  borderWidth,
  createdWithResponsiveEditor,
  hostWidth,
  isMobile,
  layoutContentHeight,
  layoutImageRatioValue,
  layoutMargins,
  layoutPostSize,
  layoutType,
  rootWidth,
  section,
  useLayoutFixerExperimentEnabled,
  useMobilePostListLayout,
}) => {
  const container = {
    width: getContainerWidth({
      borderWidth,
      createdWithResponsiveEditor,
      hostWidth,
      isMobile,
      layoutMargins: isMobile && !useMobilePostListLayout ? 0 : layoutMargins,
      layoutPostSize,
      layoutType,
      rootWidth,
      section,
      useLayoutFixerExperimentEnabled,
    }),
  };

  if (isLayoutSlider(layoutType)) {
    container.height =
      (rootWidth - layoutMargins * 2) / layoutImageRatioValue +
      layoutContentHeight;
  }

  return container;
};

export const getWrapperStyles = ({
  container,
  createdWithResponsiveEditor,
  isMobile,
  layoutMargins,
  layoutPostSize,
  layoutType,
  section,
}) => {
  const wrapperStyles = {
    ...getPostListCssVars(),
    height: container.height,
  };

  const layoutPGSideBySide = isLayoutPGSideBySide(layoutType);
  const layoutPGOneColumn = isLayoutPGOneColumn(layoutType);

  if (
    (layoutPGSideBySide || layoutPGOneColumn) &&
    section !== SECTION_MY_POSTS
  ) {
    wrapperStyles.maxWidth = layoutPostSize;
  }

  if (
    (section !== SECTION_POST_LIST &&
      section !== SECTION_RELATED_POSTS &&
      section !== SECTION_MY_POSTS) ||
    layoutPGSideBySide
  ) {
    wrapperStyles.marginTop = createdWithResponsiveEditor ? 20 : layoutMargins;
    wrapperStyles.marginBottom = createdWithResponsiveEditor
      ? 0
      : layoutMargins;

    const horizontalMargin = getWrappersHorizontalMargin({
      layoutPGSideBySide,
      layoutPGOneColumn,
      layoutMargins,
      createdWithResponsiveEditor,
    });

    wrapperStyles.marginLeft = horizontalMargin;
    wrapperStyles.marginRight = horizontalMargin;
  }

  if (isMobile) {
    wrapperStyles.margin = 0;
  }

  return wrapperStyles;
};

export const getScrollingElement = () => {
  try {
    if (typeof window !== 'undefined') {
      return window;
    } else {
      // iFrame
      // return a "mock" of the window
      return {
        addEventListener: (eventName, callback) => {
          Wix.addEventListener(eventName.toUpperCase(), callback);
        },
        removeEventListener: (eventName, callback) => {
          Wix.removeEventListener(eventName.toUpperCase(), callback);
        },
      };
    }
  } catch (e) {
    console.error('Cannot get scrolling element', e);
    return {};
  }
};

export const getPGEmptyCoverImage = (layoutType) => ({
  height: 1,
  width: 10000,
  ...(isLayoutTextOnImage(layoutType) || isLayoutSlider(layoutType)
    ? { type: 'text' }
    : {}),
});

export const getIsPrerenderMode = ({
  hostWidth,
  isPrerenderMode,
  isSEO,
  rootWidth,
}) => {
  const width = hostWidth || rootWidth;
  const isWidthUnknown = isNaN(width) || width === '';

  return isWidthUnknown && !isSEO && isPrerenderMode; // can't go back to prerendermode
};
