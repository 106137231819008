import React from 'react';
import classNames from 'classnames';

import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import { resolveId } from '@wix/communities-blog-client-common';
import withTranslate from '../../hoc/with-translate';
import { createLikeButtonMessage } from '../../services/create-like-button-message';
import {
  getIsLiked as getIsPostLiked,
  getIsLikeInProgress as getIsPostLikeInProgress,
  getLikeCount as getPostLikeCount,
  getLikeCountFormatted as getPostLikeCountFormatted,
} from '../../store/post-likes/post-likes-selectors';
import Counter from '../counter';
import LikeButton from '../like-button';
import { connect } from '../runtime-context';
import styles from './like-button-with-count.scss';

const LikeButtonWithCount = ({
  t,
  className,
  onClick,
  showZero,
  switchCounterAndButton,
  likeCount,
  likeCountFormatted,
  isLiked,
  isLikeInProgress,
}) => {
  const postLikesMessage = createLikeButtonMessage({ t, likeCount, isLiked });
  const ariaLive = isLikeInProgress ? 'polite' : 'off';

  return (
    <button className={classNames(styles.button, className)} onClick={onClick}>
      <span aria-live={ariaLive} className={styles.srOnly}>
        {postLikesMessage}
      </span>
      <span
        className={classNames(
          styles.flexContainer,
          switchCounterAndButton && styles.switchCounterAndButton,
        )}
        data-hook="like-button-with-count__like-count"
      >
        <Counter
          className={classNames(
            styles.count,
            'like-button-with-count__like-count',
          )}
          count={likeCount}
          countFormatted={likeCountFormatted}
          showZero={showZero}
          ariaHidden={true}
        />
        <LikeButton isLiked={isLiked} isDisabled={isLikeInProgress} />
      </span>
    </button>
  );
};

LikeButtonWithCount.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  entity: PropTypes.object.isRequired,
  showZero: PropTypes.bool,
  switchCounterAndButton: PropTypes.bool,
  likeCount: PropTypes.number.isRequired,
  likeCountFormatted: PropTypes.string,
  isLikeInProgress: PropTypes.bool.isRequired,
  isLiked: PropTypes.bool.isRequired,
};

LikeButtonWithCount.defaultProps = {
  showZero: false,
  switchCounterAndButton: false,
};

const mapRuntimeToProps = (state, { entity }) => ({
  likeCount: getPostLikeCount(state, resolveId(entity)),
  likeCountFormatted: getPostLikeCountFormatted(state, resolveId(entity)),
  isLikeInProgress: getIsPostLikeInProgress(state, resolveId(entity)),
  isLiked: getIsPostLiked(state, resolveId(entity)),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withTranslate,
)(LikeButtonWithCount);
