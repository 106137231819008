import React from 'react';
import PropTypes from 'prop-types';
import withTranslate from '../../hoc/with-translate';

const Counter = ({
  t,
  count,
  countFormatted,
  i18nKey,
  className,
  showZero,
  ariaHidden,
  tabIndex,
}) =>
  (count || showZero) && (
    <span aria-hidden={ariaHidden} className={className} tabIndex={tabIndex}>
      {t(i18nKey, { count, numberFormatted: countFormatted })}
    </span>
  );

Counter.propTypes = {
  count: PropTypes.number,
  countFormatted: PropTypes.string,
  i18nKey: PropTypes.string.isRequired,
  className: PropTypes.string,
  showZero: PropTypes.bool,
  t: PropTypes.func,
  ariaHidden: PropTypes.bool,
  tabIndex: PropTypes.number,
};

Counter.defaultProps = {
  count: 0,
  showZero: true,
  i18nKey: 'counter.count',
};

export default withTranslate(Counter);
